<template>
	<v-container fluid>
		<v-row>  
			<v-col class="col-12 pt-0 pb-2">
				<h1>
					{{ user.name }}
				</h1>
				<p>Usergroup: {{ user.user_group.name }}</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="col-12 pt-0 pb-0">
				<router-link :to="`/admin/user-edit/${user.id}`">
					<v-btn small>Edit</v-btn>
				</router-link>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="col4">
				<p>
					Contacts:<br />
					<span v-if="user.direct_tel">Telephone: {{ user.direct_tel }}<br /></span>
					<span v-if="user.email">Email: {{ user.email }}</span>
				</p>
			</v-col>
		</v-row>
	</v-container>
</template>
  
<script>
import axios from '../../../axios';

export default {
	name: 'AdminUsersView',
	data() {
		return {
			user: {},
		};
	},
	computed: {
		token() {
			return this.$store.state.token;
		},
	},
	methods: {
		getUser() {
			const userId = this.$route.params.id;
			axios.get(`/users/getSingle/${userId}.json?token=${this.token}`)
				.then((response) => {
					this.user = response.data.user;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	mounted() {
		this.getUser();
	},
};
</script>
